import {offerApi} from '@/api/admin/offers';

const state = () => ({
  discountRequestsList: [],
  discountRequestsListPageCount: 0,
  discountRequestsListCount: 0,
})

const mutations = {
  setDiscountRequestsList (state, data) {
    state.discountRequestsList = data.discountRequests;
    state.discountRequestsListPageCount = data.pageCount;
    state.discountRequestsListCount = data.subscriptionsCount;
  },
}

const actions = {
  async getDiscountRequestsList ({commit}, data) {
    try {
      const result = await offerApi.getDiscountRequestsPaginatedList(data.pageNumber, data.pageSize);
      commit('setDiscountRequestsList', result.data);
    } catch (e) {
      console.log('e', e)
    }
  },
}

export default {
  state,
  mutations,
  actions,
}