import Vue from 'vue'
import Vuex from 'vuex'
import messageCenter from './modules/message-center'
import travelers from './modules/travelers'
import billing from './modules/billing'
import auth from './modules/auth'
import search from './modules/search'
import info from './modules/info'
import booking from './modules/dashboard/booking'
import bookingFromSearch from './modules/booking'
import profile from './modules/dashboard/profile'
import dashboard from './modules/dashboard/dashboard'
import flights from './modules/dashboard/flights'
import passengerId from './modules/dashboard/passenger-id'
import flygreen from './modules/dashboard/flygreen'
import messages from './modules/dashboard/messages'
import auctions from './modules/dashboard/auctions'
import personnel from './modules/dashboard/personnel'
import fleet from './modules/dashboard/fleet'
import bookingConfirmation from './modules/dashboard/booking-confirm-provider'
import flightsProvider from './modules/dashboard/flights-provider'
import flyRewards from './modules/dashboard/flyrewards'
import adminRequests from './modules/dashboard/admin-requests'
import network from './modules/dashboard/network'
import usersSubscriptions from './modules/dashboard/usersSubscriptions'
import requests from './modules/dashboard/requests'
import settings from './modules/dashboard/settings'
import payment from './modules/dashboard/payment'
import routeAlerts from './modules/dashboard/route-alerts'
import blog from './modules/blog'
import flycal from './modules/dashboard/flycal';
import availabilities from './modules/dashboard/availabilities'
import providerCalendar from './modules/dashboard/providerCalendar'
import flyersBid from './modules/dashboard/bids'
import view from './modules/view'
import blogDashboard from './modules/dashboard/blog'
import argusStatistic from './modules/argusStatistic'
import subscriptions from './modules/dashboard/subscriptions'
import currencies from './modules/lookups/currencies'
import periods from './modules/lookups/periods'
import subscriptionsPlans from './modules/lookups/subscriptionsPlans'
import subscriptionsStatuses from './modules/lookups/subscriptionsStatuses'
import subscriptionsTypes from './modules/lookups/subscriptionsTypes'
import adminBids from './modules/dashboard/admin-bids';
import requestEmpty from './modules/dashboard/request-empty'
import providerBids from './modules/dashboard/provider-bids'
import subscriptionAnalytics from './modules/dashboard/subscriptionAnalytics'
import offers from './modules/dashboard/offers'
import flyAlliance from './modules/dashboard/fly-alliance'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    subscriptions,
    currencies,
    periods,
    subscriptionsPlans,
    subscriptionsStatuses,
    subscriptionsTypes,
    availabilities,
    messageCenter,
    travelers,
    billing,
    auth,
    search,
    info,
    booking,
    bookingFromSearch,
    profile,
    dashboard,
    flights,
    passengerId,
    flygreen,
    messages,
    auctions,
    personnel,
    fleet,
    bookingConfirmation,
    flightsProvider,
    flyRewards,
    adminRequests,
    adminBids,
    network,
    blog,
    requests,
    settings,
    payment,
    routeAlerts,
    flycal,
    providerCalendar,
    view,
    blogDashboard,
    argusStatistic,
    flyersBid,
    requestEmpty,
    usersSubscriptions,
    providerBids,
    subscriptionAnalytics,
    flyAlliance,
    offers,
  },
})
